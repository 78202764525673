import React, { useState } from "react"
import { useForm, ValidationError } from "@formspree/react";
import { makeStyles, useTheme } from "@material-ui/styles"
import { Checkbox, FormControlLabel, Grid, Slider, InputAdornment, RadioGroup, TextField, Typography, Radio, Divider, Button } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Layout from "../components/ui/layout"

import ContactSupportIcon from "@material-ui/icons/ContactSupport"
import DoneIcon from "@material-ui/icons/Done"


const useStyles = makeStyles(theme => ({
    alerteContainer: {
        marginTop: '6rem',
        width: "100%"
    },
    headContainer: {
        height: "auto",
        backgroundColor: theme.palette.common.clair,
        border: `1px solid ${theme.palette.common.clair}`,
        padding: "2rem 2rem",
        //marginBottom: "0.5rem",
        position: "relative",
        width: "61.5%"

    },
    Icon: {
        position: "absolute",
        left: "-1.5rem",
        top: "-1.5rem",
        width: "3rem",
        height: "3rem",
        fill: theme.palette.primary.main
    },
    appartForm: {
        color: theme.palette.common.black,
    },
    divider: {
        margin: '1rem 0',
        width: "80%"
    },
    formContainer: {
        padding: "1rem 0 4rem 0",
        // border: `1px solid ${theme.palette.common.clair}`,
        // height: "80rem",
        width: "61.5%",
        position: "relative"
    },
    rowPiece: {
        width: "100%"
    },
    budgetRow: {
        width: "100%"
    },
    textArera: {
        width: "36rem"
    },
    prenomRow: {
        margin: "1rem 0",
        width: "100%"
    },
    phoneRow: {
        margin: "1rem 0",
        width: "100%"
    },
    texteareaRow: {
        margin: "1rem 0",
        width: "100%"
    },
    headText: {
        fontSize: "0.95rem"
    },
    toggleNombre: {
        height: "2.5rem",
        paddingLeft: "1rem",
        width: "12rem"
    },
    typeRow: {
        width: "100%"
    },
    label: {
        width: "9.5rem",
        marginRight: "0.5rem"
    },
    corLabel: {
        width: "7.5rem",
        marginRight: "0.5rem"
    },
    rechercheRow: {
        width: "50rem",
        // margin: "1rem 1rem"
    },
    toggleRow: {
        width: "22rem"
    },
    toggleLabel: {
        width: "9.5rem"
    },
    budgetField: {
        width: "12rem"
    },
    proprioRow: {
        width: "100%"
    },
    "@global": {
        ".MuiToggleButton-root.Mui-selected": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            "&:hover": {
                backgroundColor: theme.palette.secondary.light
            }
        },
    },
    submitButton: {
        position: "absolute",
        right: "3rem",
        bottom: "3rem",
        "&:hover": {
            backgroundColor: theme.palette.secondary.light
        }
    },
    infoBien: {
        borderLeft: `5px solid ${theme.palette.secondary.main}`,
        paddingLeft: "0.5rem"
    },
    sectionTitle: {
        color: theme.palette.primary.main
    },
    petitLabel: {
        width: "6rem"
    },
    succeededContainer: {
        borderLeft: `10px solid green`,
        backgroundColor: "#c0dfa8",
        padding: "1rem 1rem"
    },
    doneIcon: {
        fill: "green",
        size: "3rem"
    }
}))



const Alerte = () => {
    const theme = useTheme()
    const classes = useStyles()

    const matchesSM = useMediaQuery(theme => theme.breakpoints.down("sm"))

    // const [proprio, setProprio] = useState("non")

    const [state, handleSubmit] = useForm("mjvljnbd", {
        data: {
            subject: 'Alerte envoyée depuis le site web immodefi',
            pageTitle: function () {
                // This function will be evaluated at submission time
                return document.title;
            }
        }
    });

    //   const handleRedirection = () => {
    //       setTimeout(()=> {
    //           window.location.href = `/`
    //       }, 3000)
    //   }

    // const [pieceMin, setPieceMin] = useState(1)

    // const [chambreMin, setChambreMin] = useState(1)


    // const handleProprio = (e, newVal) => {
    //     setProprio(newVal)
    // }

    // const handlePieceMin = (e, newVal) => {
    //     setPieceMin(newVal)
    // }

    // const handleChambreMin = (e, newVal, min) => {
    //     if (newVal < min) {
    //         setChambreMin(min)
    //     } else {
    //         setChambreMin(newVal)
    //     }
    // }

    // const minDistance = 1

    // const [sliderValue, setSliderValue] = useState([2, 5])

    // const handleSliderChange = (event, newValue, activeThumb) => {
    //     if (!Array.isArray(newValue)) {
    //         return;
    //     }

    //     if (newValue[1] - newValue[0] < minDistance) {
    //         if (activeThumb === 0) {
    //             const clamped = Math.min(newValue[0], 10 - minDistance)
    //             setSliderValue([clamped, clamped + minDistance])
    //         } else {

    //         }
    //     }
    // }

    return (<Layout>
        <Grid container classes={{ root: classes.alerteContainer }} justifyContent="space-between" alignItems="center" direction="column">
            <Grid item container alignContent="center"
                alignItems="flex-start"
                justifyContent="space-around"
                direction="column"
                classes={{ root: classes.headContainer }}>
                <Grid item>
                    <ContactSupportIcon classes={{ root: classes.Icon }} />
                    <Grid item>
                        <Typography style={{ color: theme.palette.common.black }} variant="h1">Trouvez le bien de vos rêves.</Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{ color: theme.palette.common.black }} classes={{ root: classes.headText }} >Soyez-informé en avant-première dès que nous disposons d'un bien qui correspond à ce que vous recherchez</Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{ color: theme.palette.common.black }} classes={{ root: classes.headText }} >Il vous suffit d'indiquer vos coordonnées et vos principaux critères.</Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{ color: theme.palette.common.black }} classes={{ root: classes.headText }} >Recevez nos annonces correspondant à vos critères de recherche avant même leur diffusion</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container
                classes={{ root: classes.formContainer }}
                alignContent="center"
                alignItems="flex-start"
                justifyContent="space-around" direction="column">
                {state.succeeded ? (
                    <Grid item container classes={{root: classes.succeededContainer}} justifyContent="space-between" direction="row">
                        <Grid item>
                            <Typography variant="body2">
                                Merci d'avoir créer une alerte. Nous ne tarderons pas à vous trouvez la maison qu'il vous faut
                            </Typography>
                        </Grid>
                        <Grid item>
                            <DoneIcon classes={{root: classes.doneIcon}} />
                        </Grid>
                    </Grid>
                ) : (
                    <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                        <Grid item classes={{ root: classes.infoBien }}>
                            <Typography classes={{ root: classes.sectionTitle }} variant="h1">
                                Les informations du bien
                            </Typography>
                        </Grid>
                        <Grid item container classes={{ root: classes.typeRow }}
                            alignItems={matchesSM ? "flex-start" : "center"}
                            justifyContent="flex-start"
                            direction={matchesSM ? "column" : "row"}>
                            <Grid item>
                                <Typography style={{ color: theme.palette.common.black }}>Type de bien :</Typography>
                            </Grid>
                            <Grid item container style={{ width: "30rem" }} direction="row">
                                <Grid item>
                                    <FormControlLabel type="checkbox" name="Appartement" control={<Checkbox />} labelPlacement="start"
                                        classes={{ lable: classes.appartForm }} label="Appartement" />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel type="checkbox" name="Maison" control={<Checkbox />} labelPlacement="start"
                                        classes={{ lable: classes.appartForm }} label="Maison" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container style={{ width: matchesSM ? "22rem" : "100%" }} classes={{ root: classes.rechercheRow }} justifyContent="space-between" direction={matchesSM ? "column" : "row"}>
                            <Grid item container style={{ margin: "1rem 0", width: "22rem" }} alignItems="flex-end" direction="row">
                                <Grid item>
                                    <Typography classes={{ root: classes.label }}>Zone de recherche *</Typography>
                                </Grid>
                                <Grid item>
                                    {/* <input type="text" name="zone" style={{width: "12rem"}}  /> */}
                                    <TextField name="Zone de recherche" required style={{ width: "12rem" }} placeholder="Entrez une ou plusieurs" variant="outlined" />
                                </Grid>
                            </Grid>
                            <Grid item container style={{ margin: "1rem 0", width: "22rem" }} alignItems="center" direction="row">
                                <Grid item>
                                    <Typography classes={{ root: classes.label }}>Surface minimum *</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField required name="surface minimum" style={{ width: "12rem" }} placeholder="Surface minimum"
                                        InputProps={{ endAdornment: (<InputAdornment position="end">m2</InputAdornment>) }}
                                        variant="outlined" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container classes={{ root: classes.rowPiece }} justifyContent="space-between" direction={matchesSM ? "column" : "row"}>
                            <Grid item container style={{ margin: "1rem 0" }} alignItems={matchesSM ? "flex-start" : "center"} classes={{ root: classes.toggleRow }} justifyContent="space-between" direction={matchesSM ? "column" : "row"}>
                                <Grid item>
                                    <Typography classes={{ root: classes.toggleLabel }}>Nombre de pièces minimum *</Typography>
                                </Grid>
                                <Grid item>
                                    <select className={classes.toggleNombre} name="Nombre de pieces minimum" >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </select>
                                    {/* <ToggleButtonGroup name="pieceMin" type="select" onChange={handlePieceMin} classes={{ root: classes.toggleNombre }} value={pieceMin} exclusive>
                                        <ToggleButton value={1}>1</ToggleButton>
                                        <ToggleButton value={2}>2</ToggleButton>
                                        <ToggleButton value={3}>3</ToggleButton>
                                        <ToggleButton value={4}>4</ToggleButton>
                                        <ToggleButton value={5}>5</ToggleButton>
                                    </ToggleButtonGroup> */}
                                </Grid>
                            </Grid>
                            <Grid item container style={{ margin: "1rem 0" }} classes={{ root: classes.toggleRow }} alignItems={matchesSM ? "flex-start" : "center"} justifyContent="space-between" direction={matchesSM ? "column" : "row"}>
                                <Grid item>
                                    <Typography classes={{ root: classes.toggleLabel }}>Nombre de pièces maximum *</Typography>
                                </Grid>
                                <Grid item>
                                    <select className={classes.toggleNombre} name="Nombre de pieces maximum" >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8 et plus">8 et plus</option>
                                    </select>
                                    {/* <ToggleButtonGroup name="pieceMax" type="select" classes={{ root: classes.toggleNombre }} value={chambreMin} onChange={(e) => handleChambreMin(e.target.value, pieceMin)} exclusive>
                                        <ToggleButton value={1}>1</ToggleButton>
                                        <ToggleButton value={2}>2</ToggleButton>
                                        <ToggleButton value={3}>3</ToggleButton>
                                        <ToggleButton value={4}>4</ToggleButton>
                                        <ToggleButton value={5}>5</ToggleButton>
                                    </ToggleButtonGroup> */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="space-between" classes={{ root: classes.budgetRow }} direction={matchesSM ? "column" : "row"}>
                            <Grid item container style={{ margin: "1rem 0", width: "22rem" }} alignItems={matchesSM ? "flex-start" : "center"} direction={matchesSM ? "column" : "row"}>
                                <Grid item>
                                    <Typography budgetField classes={{ root: classes.label }}>Budget minimum *</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField required name="budget minimum" classes={{ root: classes.budgetField }} placeholder="Budget minimum"
                                        InputProps={{ endAdornment: (<InputAdornment position="end">€</InputAdornment>) }}
                                        variant="outlined" />
                                </Grid>
                            </Grid>
                            <Grid item container style={{ margin: "1rem 0", width: "22rem" }} alignItems={matchesSM ? "flex-start" : "center"} direction={matchesSM ? "column" : "row"}>
                                <Grid item>
                                    <Typography classes={{ root: classes.label }}>Budget maximum *</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField required name="budget maximum" placeholder="Budget maximum" classes={{ root: classes.budgetField }}
                                        InputProps={{ endAdornment: (<InputAdornment position="end">€</InputAdornment>) }}
                                        variant="outlined" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={matchesSM ? "flex-start" : "center"} direction={matchesSM ? "column" : "row"}>
                            <Grid item>
                                <Typography>Type d'achat *:</Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup name="type d'achat" row aria-label="type" >
                                    <FormControlLabel value="Residence principale" labelPlacement="start" control={<Radio />} label="Résidence principale" />
                                    <FormControlLabel value="Residence secondaire" labelPlacement="start" control={<Radio />} label="Résidence secondaire" />
                                    <FormControlLabel value="Investissement locatif" labelPlacement="start" control={<Radio />} label="Investissement locatif" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid item container classes={{ root: classes.texteareaRow }} direction={matchesSM ? "column" : "row"}>
                            <Grid item>
                                <Typography classes={{ root: classes.toggleLabel }}>Autres precision s'il y'en a :</Typography>
                            </Grid>
                            <Grid item>
                                <TextField name="precisions" classes={{ root: classes.textArera }} placeholder="Autre Précision" multiline minRows={5} variant="outlined" />
                            </Grid>
                        </Grid>
                        <Divider classes={{ root: classes.divider }} />
                        <Grid item classes={{ root: classes.infoBien }}>
                            <Typography classes={{ root: classes.sectionTitle }} variant="h1">
                                Vos coordonnées
                            </Typography>
                        </Grid>
                        <Grid item container classes={{ root: classes.prenomRow }} justifyContent="space-between" direction={matchesSM ? "column" : "row"}>

                            <Grid item container style={{ width: "22rem" }} alignItems="center" direction="row">
                                <Grid item>
                                    <Typography classes={{ root: classes.petitLabel }}>Prénom *</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField required name="prenom" style={{ width: "12rem" }} placeholder="Prénom" variant="outlined" />
                                </Grid>
                            </Grid>
                            <Grid item container style={{ width: "22rem" }} alignItems="center" direction="row">
                                <Grid item>
                                    <Typography classes={{ root: classes.petitLabel }}>Nom *</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField required name="nom" style={{ width: "12rem" }} placeholder="Nom" variant="outlined" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container classes={{ root: classes.phoneRow }} justifyContent="space-between" direction={matchesSM ? "column" : "row"}>
                            <Grid item container style={{ width: "22rem" }} alignItems="center" direction="row">
                                <Grid item>
                                    <Typography classes={{ root: classes.petitLabel }}>Téléphone *</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField required type="tel" name="telephone" style={{ width: "12rem" }} placeholder="Numéro de téléphone" variant="outlined" />
                                </Grid>
                            </Grid>
                            <Grid item container style={{ width: "22rem" }} alignItems="center" direction="row">
                                <Grid item>
                                    <Typography classes={{ root: classes.petitLabel }}>Email *</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField required name="email" type="email" style={{ width: "12rem" }} placeholder="Email" variant="outlined" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container classes={{ root: classes.proprioRow }} justifyContent="flex-start" alignItems="center" direction={matchesSM ? "column" : "row"}>
                            <Grid item>
                                <Typography style={{ marginRight: "1rem" }}>Etes vous déjà propriétaire ? *</Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup name="Deja proprietaire" row aria-label="type" >
                                    <FormControlLabel value="Oui" labelPlacement="start" control={<Radio />} label="Oui" />
                                    <FormControlLabel value="Non" labelPlacement="start" control={<Radio />} label="Non" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Button type="submit" disabled={state.submitting} classes={{ root: classes.submitButton }} style={{ backgroundColor: theme.palette.secondary.main }}>
                                <Typography style={{ color: theme.palette.common.white }}>Créer l'alerte</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            < ValidationError errors={state.errors} />
                        </Grid>
                    </form>
                )}
            </Grid>
        </Grid>
    </Layout>)
}

export default Alerte